.hero {
  position: relative;
  width: 100%;
  height: 100vh;

  &__ttl {
    color: $cWhite;
    font-size: 40px;
    position: absolute;
    bottom: 18%;
    left: 10%;
    z-index: 10;

    & > h1 {
      margin-bottom: 0;
    }
  }

  &__scroll {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
  }
}


/* スライダー全体 */
.slider-animation {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: #000;
}

/* スライダー画像 */
.slider-animation .image {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/*
①スライド画像
表示する画像を定義
*/
.slider-animation .image:nth-of-type(1) {
  animation-delay: 0s;
  background-image: url(../images/hero1.jpg);
}

.slider-animation .image:nth-of-type(2) {
  animation-delay: 6s;
  background-image: url(../images/hero2.jpg);
}
.slider-animation .image:nth-of-type(3) {
  animation-delay: 10s;
  background-image: url(../images/hero3.jpg);
}


/*
②アニメーション設定
animation-durationのみ変更
*/
.slider-animation .image {
  animation-name: anime;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  /* ①で設定したanimation-delayを合計を挿入 */
}

/* アニメーション定義 */
@keyframes anime {
  0% {
      opacity: 0;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0;
      transform: scale(1.15);
  }
}

/*========= スクロールダウンのためのCSS ===============*/

/*スクロールダウン全体の場所*/
.scrolldown4 {
  /*描画位置※位置は適宜調整してください*/
  position: absolute;
  bottom: 5%;
  left: 50%;
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  line-height: 1;
  width: 18px;
  height: 18px;
  border: 0.1em solid currentColor;
  border-left: 0;
  border-bottom: 0;
  box-sizing: border-box;
  transform: translateX(-50%) translateY(-25%) rotate(135deg);
  margin-bottom: 20px;
  /*矢印の動き1秒かけて永遠にループ*/
  animation: arrowmove 1.8s ease-in-out infinite;
}

/*下からの距離が変化して全体が下→上→下に動く*/
@keyframes arrowmove {
  0% {
    bottom: 1%;
  }
  50% {
    bottom: 2%;
  }
  100% {
    bottom: 1%;
  }
}

.swiper-wrapper {
  /* wrapperのサイズを調整 */
  width: 100%;
  height: 300px;
}

.swiper-slide {
  /* スライドのサイズを調整、中身のテキスト配置調整、背景色 */
  color: #ffffff;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 300px;
  text-align: center;

  & > img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  // &-active {
  //   .main-visual {
  //     transform: scale(1.1);
  //   }
  
  // }
}

// .main-visual {
//   width: 100%;
//   height: 100%;
//   max-width: 100%;
//   object-fit: cover;
//   transform: scale(1.0) ;
//   transition: transform 3s ease;
// }



.swiper-pagination-bullet {
  width: 35px !important;
  height: 5px !important;
  background-color: #ffffff !important;
  border-radius: 0% !important;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  left: 40% !important;
  bottom: 30px !important;
}

.slick-next:before {
  content: url(../images/slick-next.png) !important;
  position: relative;
  bottom: 25px;
  right: -35px;
}
.slick-prev:before {
  content: url(../images/slick-prev.png) !important;
  position: relative;
  bottom: 20px;
  left: -35px;
}

.swiper-container {
  /* スライド アクティブ時 */
  .swiper-slide {
    &[class*=-active] {
      .slide-media img {
        transition-delay: 0s;
        transform: scale(1.05);
      }
      .slide-title {
        animation: mv01-fadeIn 2s .5s var(--easing) both;
      }
    }
  }
}
 
@keyframes mv01-fadeIn {
  0% {
    transform: scale(.5);
    opacity: 0;
    filter: blur(300px);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
  }
}








