html {
  scroll-behavior: smooth;
}
body {
  font-family: "Noto Sans JP", sans-serif;
  color: #373f49;
}
img {
  max-width: 100%;
  vertical-align: bottom;
}
a {
  color: #373f49;
  &:hover {
    color: #373f49;
    text-decoration: none;
    opacity: 0.7;
  }
}
.fo-robot {
  font-family: "Roboto", sans-serif;
}
.font-sm {
  font-size: 13px;
}
.font-md {
  font-size: 14px;
}
.font-lr {
  font-size: 18px;
}
.font-lg {
  font-size: 26px;
}

.content-width {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
}

#global-container {
  overflow: hidden;
}

.po-md {
  position: relative;
  top: 20px;
}

/*-------------------------------------------
ヘッダー
-------------------------------------------*/

/*
ハンバーガ―メニュー
*/
.hamburger {
  display: none;
}

/* ロゴに関するスタイル */
.header-logo {
//  width: 35%;
}

.header-logo.headerLogoScroll.-before {
  display: none;
}

.header-logo.-after {
  display: none;
}

.header-logo.headerLogoScroll.-after {
  display: block;
  margin-top: 27px;
}


.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  z-index: 100;

  &.change {
    background-color:rgba($color: #fff, $alpha: 1.0)
  }

  &__logo {
    width: 300px;
    margin-bottom: 20px;
  }

  &-ttl {
    color: #fff;
    font-size: 12px;
    position: relative;
    top: 20px;
    left: 5px;

    &.change-color {
      color: #373f49;
    }
  }

  &-sec {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    z-index: 100;

    &.triggered {
      background-color: #fff;
    }

  }


  &__nav {
    display: block;
    position: relative;
  }

  &__tel {
    font-size: 26px;
    position: absolute;
    right: 33%;
    @extend .fo-robot;
    font-weight: 500;
    & > a {
      color: #fff;
      font-size: 18px;

      &.change-color {
        color: #00437c;
      }
    }
  }
  &__ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;

    &-wrapper {
      padding-top: 30px;
      display: flex;
      flex-wrap: row;
    }
  }
  &__li {
    margin-left: 25px;
    position: relative;
    border-right: 1px solid #fff;
    padding-right: 25px;

    &.change-color {
      border-right: 1px solid #373f49;
    }

    &:nth-child(4) {
      border-right: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 5px;
    //   right: -23px;
    //   width: 1px;
    //   height: 17px;
    //   background-color: #fff;
    // }

    & > a {
      font-size: 16px;
      display: block;
      color: $cWhite;
      text-decoration: none !important;
      text-transform: uppercase;

      &.change-color {
        color: #373f49;
      }

      & > span {
        font-size: 16px;
      }
      & > img {
        position: relative;
        top: 25px;
        left: -4px;
      }
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 60px;
  }
}

.txt-p {
  text-align: left;
}
// 事業紹介
.bu {
  &__bg {
    background-image: url(../images/business_bg.jpg);
    background-position: left;
    background-size: 65% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
    margin-bottom: 250px;

    .corporate:nth-child(2) & {
      background-image: url(../images/corporate_bg.jpg);
      background-position: right;
    }
  }

  &__ttl {
    text-align: right;

    .corporate:nth-child(2) & {
      text-align: left;
    }

    & > h2 {
      @extend .fo-robot;
      color: $cBlue;
      font-size: 130px;
      font-weight: 100;
      margin-bottom: 0;
    }
  }
  &__panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .corporate:nth-child(2) & {
      flex-direction: row-reverse;
    }

    &-wrap {
      padding: 80px 0 0 0;
      width: 90%;
      margin: 0 auto;
      max-width: 1200px;
    }

    &-ttl {
      margin-bottom: 45px;
      & > img {
        position: relative;
        bottom: 5px;
      }
      &-span {
        font-size: 26px;
        color: $cBlue;
        font-weight: bold;
      }
    }

    &-txt {
      &-center {
        margin-bottom: 45px;
        & > p {
          font-size: 18px;
          color: $cBlue;
          margin-bottom: 0;
          letter-spacing: 0.05em;
          line-height: 1.8;
          font-weight: bold;
        }
      }
      &-bottom {
        & > p {
          font-size: 14px;
          margin-bottom: 0;
          line-height: 2;
        }
      }
    }

    &-left {
      width: 45%;
    }
    &-right {
      width: auto;
    }
  }

  &__inner {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
  }

  &__card {
    width: 47%;
    margin-bottom: 90px;

    &-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &-blk {
      display: flex;
      flex-direction: row;
    }

    &-row {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
    }

    &-img {
      width: 40%;
     
      position: relative;
      bottom: 30px;
    }

    &-des {
      // width: 65%;
    }

    &-ttl {
      @extend .font-lr;
      background-color: $cBlue;
      color: #fff;
      padding: 3px 0 3px 7px;
      width: 70%;
      margin: 0 0 0 auto;
    }
    &-txt {
      @extend .font-md;
      line-height: 2;
      width: 90%;
      margin-bottom: 0;
      margin-top: 10px;
    }

    &-bottom {
      width: 95%;
      margin: 0 0 0 auto;
      @extend .font-sm;
      margin-top: 15px;
      &-ttl {
        margin-bottom: 10px;
      }
    }
  }

  &__map {
    width: 50%;
  }

  &__access {
    @extend .content-width;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  &__enkaku {
    width: 45%;
    @extend .font-md;

    &-ttl {
      margin-bottom: 10px;
    }
    &-time {
      color: $cBlue;
      margin-bottom: 3px;
    }
    &-txt {
      margin-bottom: 17px;
    }
  }
}

// テーブル
table {
  width: 100%;
}
tr {
  font-size: 14px;
}
tr td:first-child {
  color: $cBlue;
  width: 20%;
}
td {
  padding: 6px 0;
}

// リクルート
#recruit {
  background-image: url(../images/recruit_bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  padding-top: 90px;
}

.re {
  &__inner {
    @extend .content-width;
  }

  &__txt {
    margin-top: 30px;
    margin-bottom: 30px;
    & > p {
      margin-bottom: 0;
      color: $cWhite;
      font-weight: bold;
    }
  }

  &__box {
    width: 100%;
    background-color: #fff;
    padding: 50px 30px;

    &-blk {
      font-size: 14px;
      display: flex;
      justify-content: start;
    }
    &-ttl {
      color: $cBlue;
      width: 13%;
      margin-bottom: 16px;
    }
    &-txt {
      width: 80%;
    }
  }
}

.btn-blue-sp {
  display: none;
}

// コンタクト
#contact {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}
.contact {
  &__ttl {
    & > h3 {
      font-size: 90px;
      @extend .fo-robot;
      color: $cBlue;
      margin-bottom: 0;
      font-weight: 100;
    }

    &-ja {
      & > p {
        margin-bottom: 0;
        position: relative;
        bottom: 20px;
        font-size: 26px;
        color: $cBlue;
        font-weight: bold;
      }
    }
  }
  &__btn {
    & > a {
      margin: 0 auto;
    }
  }

  &__center {
    font-size: 18px;
    margin-bottom: 40px;
    margin-top: 10px;
    color: $cBlue;
    font-weight: bold;
  }
}

.after-no-last {
  &::after {
    content: none !important;
  }
}
.footer {
  position: relative;

  &__nav {
    padding: 28px 0;
    border-top: 1px solid #373f49;
  }

  &__ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  &__li {
    list-style: none;

    & > a {
      font-size: 14px;

      &::after {
        content: "";
        background-color: #373f49;
        width: 1px;
        height: 15px;
        color: #373f49;
        margin: 0 30px;
        display: inline-block;
      }
    }
  }

  &__txt {
    padding-left: 40px;
    font-size: 14px;
  }

  &__inner {
    background-color: $cBg;
    padding: 30px 0;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @extend .content-width;
  }

  &__left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  &__right {
    font-size: 12px;
  }

  &__logo {
    margin-right: 30px;
  }

  &__company {
    font-size: 16px;
  }

  &__add {
    font-size: 12px;
    margin-top: 23px;
  }
}

// お知らせページ
.current-info {
  border-bottom: 2px solid #00437c !important;
  color: #00437c !important;
}
.main {
  &__inner {
    @extend .content-width;
  }

  &__ttl {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 50px;

    &-top {
      font-weight: 100;
      font-size: 80px;
      color: $cBlue;
      @extend .fo-robot;
    }

    &-sub {
      margin-left: 20px;
      position: relative;
      top: 8px;

      & > span {
        font-size: 20px;
        color: #00437c;
      }
    }
  }
}
.br {
  &__nav {
    margin-bottom: 10px;
  }
  &__ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  &__li {
    list-style: none;
    margin-right: 7px;
    font-size: 12px;
  }
}
.bu {
  &__bg {
    &-sec {
      background-image: url(../images/business_bg.jpg);
      background-position: left top;
      background-size: 70% 50%;
      background-repeat: no-repeat;
      width: 100%;
      margin-top: 75px;
    }

    &-thr {
      background-image: url(../images/contact_bg.jpg);
      background-position: left top;
      background-size: 70% 40%;
      background-repeat: no-repeat;
      width: 100%;
      margin-top: 75px;
    }
  }
}
.ttl-sec {
  font-size: 80px;
  color: $cBlue;
  @extend .fo-robot;
  margin-right: 60px;
}

.content {
  &__sec {
    padding: 50px 60px;
    background-color: #fff;
  }
  &__thr {
    padding: 50px 60px;
    background-color: #fff;
  }
  &__info {
    background-image: url(../images/circle-before.png);
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      background-image: url(../images/circle-after.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.he-lr {
  height: 650px;
}
.info {
  &__blk {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    &-a {
      padding-bottom: 10px;
      width: 80%;
      &:hover {
        opacity: 0.8;
        border-bottom: 1px solid #00437c;
        padding-bottom: 10px;
      }
    }
  }

  &__date {
    color: #00437c;
    margin-right: 20px;
  }

  &__top {
    &-date {
      color: #00437c;
      margin-bottom: 10px;
    }
    &-ttl {
      margin-bottom: 30px;
    }
  }

  &__des {
    &-txt {
      font-size: 14px;
      line-height: 2;
      margin-bottom: 60px;
    }
  }
}

// プライバシーポリシーページ
.pr {
  &__blk {
    font-size: 14px;
    margin-bottom: 30px;
  }
  &__ttl {
    color: $cBlue;
    margin-bottom: 10px;
  }
  &__name {
    font-size: 14px;
    margin: 50px 0 0 auto;
    width: 180px;
  }
  &__btn {
    & > a {
      margin: 100px auto 70px;
    }
  }
}

// お問い合わせページ

.co {
  &__txt {
    margin-bottom: 40px;
  }
  &__tel {
    color: #00437c;
    font-size: 50px;
    margin: 20px 0 40px 0;

    &-blk {
      background-color: #00437c;
      width: 230px;
      color: #fff;
      text-align: center;
      padding: 3px 0;
    }
  }

  &__mail {
    &-blk {
      background-color: #00437c;
      width: 300px;
      color: #fff;
      text-align: center;
      padding: 3px 0;
      margin-bottom: 20px;
    }
  }

  &__form {
    &-txt {
      line-height: 2;
      font-size: 14px;
      & > span {
        padding: 0 8px;
        width: 45px;
        text-align: center;
        background-color: #b82b36;
        color: #fff;
        margin-right: 2px;
        margin-left: 10px;
      }
    }
  }

  &__tha {
    &-ttl {
      color: #00437c;
      font-size: 20px;
      margin-bottom: 40px;
    }
    &-txt {
      font-size: 16px;
      margin-bottom: 30px;
      &-bottom {
        margin-bottom: 60px;
      }
    }
  }
}
.tel {
  font-size: 30px;
  @extend .fo-robot;
  color: #00437c;
  font-weight: bold;
}
.time {
  font-size: 14px;
  color: #373f49;
}

.form > table > tbody > tr {
  margin: 20px 0;
  display: block;
}

.form > table > tbody > tr > th {
  display: block;
  font-size: 14px;
  font-weight: normal;
  padding: 8px 0 8px 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 5px;
    height: 30px;
    background-color: #00437c;
  }
}
.form > table > tbody > tr > td {
  display: block;
  color: #666565;
  font-size: 14px;
}
.red {
  padding: 0 8px;
  width: 45px;
  text-align: center;
  background-color: #b82b36;
  color: #fff;
  margin-right: 2px;
  margin-left: 10px;
}

.form {
  width: 100%;
}

input[type="text"] {
  width: 100%;
  border: 1px solid #57524d;
  padding: 8px 10px;
  font-size: 12px;
  max-width: 800px;
}
.mw-form input[type="text"] {
  width: 25%;
  margin-left: 10px;
  border: 1px solid #57524d;
}
.zip__span {
  font-size: 13px;
}
.agree {
  margin-top: 40px;
}
.agree-1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="checkbox"] {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
.line {
  color: $cBlue;
  border-bottom: 1px solid $cBlue;
}

input[type="submit"] {
  background: #9bb2cc url(../images/yajirushi_wh.png) 94% center no-repeat;
  font-size: 14px;
  background-color: $cBlue;
  width: 350px;
  padding: 21px 0;
  line-height: 2;
  display: block;
  text-align: center;
  color: $cWhite;
  border: 1px solid transparent;
  margin: 60px auto 90px;
}
textarea {
  width: 100%;
}
.thanks {
  width: 90%;
  margin: 60px auto;
  max-width: 800px;
  line-height: 2;
  letter-spacing: 0.1rem;
  text-align: center;

  &__images {
    width: 300px;
    height: 120px;
    margin: 60px auto 90px;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.co {
  &__current {
    width: 30%;
    background-color: #e5e8ef;
    transform: skew(-20deg);
    &-wrap {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }
}

ul.test3 {
  list-style: none;
  display: flex; /*横並びにする*/
  width: 100%;
  margin: 50px 0;
  padding: 0;
}
ul.test3 li {
  text-align: center;
  width: 30%;
  height: 40px;
  position: relative;
  line-height: 2.3;
  padding: 0 3px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -13px;
    background-image: url(../images/contact_ttl.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 40px;
    z-index: 2;
  }
}
ul.test3 li a {
  height: 100%;
  display: block;
  position: relative;
  padding: 5px 15px;
  text-decoration: none;
  font-size: 12px;
  color: #00437c;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
}
ul.test3 li a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #e5e8ef;
  transform: skewX(-19deg);
}

.current-co {
  color: #fff !important;

  &::before {
    background-color: #00437c !important;
    width: 99%;
    margin: 0 auto;
  }
}
a:not([href]):not([tabindex]) {
  color: $cBlue;
}
