@media screen and (max-width: 780px) {
  .bu {
    &__ttl {
      & > h2 {
        font-size: 70px;
      }
    }
  }

  .re {
    &__box {
      &-ttl {
        margin-bottom: 5px;
        width: 50%;
      }
      &-blk {
        flex-direction: column;
        margin-bottom: 15px;
      }
    }
  }

  .bu {
    &__card {
      width: 100%;
      &-wrap {
        flex-direction: column;
      }

      &-blk {
        margin-top: 15px;
        justify-content: space-between;
      }

      &-row {
      }

      &-img {
        bottom: 0;
        width: auto;
      }

      &-des {
        // width: 65%;
      }

      &-ttl {
        width: 100%;
        text-align: center;
      }
      &-txt {
        margin-top: 0;
        width: 70%;
      }

      &-bottom {
        margin-top: 26px;
      }
    }
  }

  .main {
      &__inner {
          width: 95%;
      }
    &__ttl {
      flex-direction: column;
      align-items: baseline;

      &-top {
        font-size: 60px;
      }
    }
  }

  .bu {
    &__bg {
      &-sec {
        background-size: 130%;
      }
      &-thr {
        background-size: 130%;
      }
    }
  }
  .br {
    &__ul {
      margin: 40px 0 20px 0;
    }
  }
  .content {
      &__sec {
          padding: 30px 20px;
      }
      &__thr {
          padding: 30px 20px;
      }
  }
  .tel {
      font-size: 16px;
  }
  .time {
      display: block;
  }

  .co {
      &__tel {
          font-size: 28px;
      }
  }

  ul.test3 {
      justify-content: center;
  }
  .form > table > tbody > tr {
      flex-direction: column;
      align-items: flex-start;
  }
}
@media screen and (max-width: 800px) {
  .houses {
    margin-top: 60px;

    &__inner {
      flex-direction: column;
    }

    &__item {
      width: 100%;
    }
  }
}
