@media  screen and (max-width:480px) {
    .header {
        &-ttl {
            font-size: 10px;
        }

        &__logo {
            width: 260px;
        }
    }
    .footer {
        &__right {
            font-size: 10px;
        }

        &__li {
            width: 33%;
        }
    }

    .main {
        &__ttl {
            padding-top: 20px;
            &-top {
                font-size: 50px;
            }
        }
    }
    .br {
        &__ul {
            margin: 30px 0 10px 0;
        }
    }

    .main {
        &__ttl {
            &-sub {
                top: 0;
            }
        }
    }
    .pr {

        &__btn {
          & > a {
            width: 300px;
          }
        }
      }

      .thumbnail {
        position: relative;
        left: 16px;
    }

      .info {
         
        &__ttl {
         &-a {
             margin-right: 20px;
         }
        }
      }

      input[type="submit"] {
        width: 300px;
      }

      .form {
          width: 100%;
      }
      .zip {
          &__span {
              display: block;
          }
      }

      .mw-form input[type="text"] {
        width: 50%;
        margin-left: 10px;
        border: 1px solid #57524d;
    }

    .mwform-checkbox {
        font-size: 14px;
    }

}
