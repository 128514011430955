@media screen and (max-width: 600px) {
 
  .hero {
    &__ttl {
      font-size: 20px;
    }
    &__scroll {
      bottom: 9%;
    }
  }


  .bu {
    &__card {
      &-txt {
        width: 65%;
      }

      &-img {
        margin-right: 5%;
      }
    }
  }

  tr td:first-child {
    width: 17%;
  }

  .re {
    &__box {
      padding: 30px 20px;
      &-txt {
        width: 100%;
      }
    }
  }

  .slick-next:before {
    right: 0px;
  }
  .slick-prev:before {
    left: -10px;
  }

  .thumbnail {
    width: 40%;
}

  .info {
    &__blk {
      &-a {
        margin-right: 20px;
        width: auto;
      }
    }
  }
  .footer {
    &__wrap {
      width: 95%;
    }
  }
}
