.btn {
  position: relative;
  display: inline-block;
 
  &.filled {
    background-image: url(../images/contact.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 190px;
    height: 70px;
    line-height: 70px;
    text-align: center;
  }

  &-blue {
    font-size: 14px;
    background-color: $cBlue;
    width: 350px;
    padding: 21px 0;
    line-height: 2;
    display: block;
    text-align: center;
    color: $cWhite;

    &-sp {
      font-size: 14px;
      background-color: $cBlue;
      width: 350px;
      padding: 21px 0;
      line-height: 2;
      display: block;
      text-align: center;
      color: $cWhite;

      &::after {
        content: '';
        background-image: url(../images/yajirushi_wh.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 28px;
        position: absolute;
        top: 22px;
        right: 24px;
      }
    }
    
    &::after {
      content: '';
      background-image: url(../images/yajirushi_wh.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 28px;
      position: absolute;
      top: 22px;
      right: 24px;
    }

    & > img {
      position: absolute;
      right: 20px;
    }

    &:hover {
      color: $cBlue;
      background-color: #E5E8EF;

      &::after {
        background-image: url(../images/yajirushi_bl.png);
      }
    }
  }

}

.info {
  &__box {
    width: 90%;
    margin: 30px auto 0;
    max-width: 1200px;
    background-color: #fff;
    padding: 30px 40px;
    &-ttl {
      color: $cBlue;
      margin-right: 20px;
    }

    &-blk {
      display: block;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

.fill-blue {
  background-color: #00437C;
}
.fill-white {
  background-color: #fff;
  
}
.blue-txt {
  color: #00437C !important;
}

// フッターボタン
.button {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 30;

  /*デフォルトで非表示にする*/
  opacity: 0;
  visibility: hidden;
}

/*このクラスが付与されると表示する*/
.active {
  opacity: 1;
  visibility: visible;
}

/*このクラスが付与されると表示する*/
.absolute {
  position: absolute;
  top: -45px;
  bottom: auto;
}
