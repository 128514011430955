.hamburger {
    width: 75px;
    height: 75px;
    background-color: #00437c;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 30;
    cursor: pointer;
    transition: 0.3s;
  }
  .hamburger:hover {
    opacity: 0.7;
  }
  /* ハンバーガーメニューの線の設定（メニューが閉じている時） */
  .hamburger span {
    width: 40px;
    height: 2px ;
    background: $cWhite;
    position: absolute;
    left: 21px;
    transition: 0.3s ease-in-out;
  }
  /* 1本目の線の位置を設定 */
  .hamburger span:nth-child(1) {
    top: 36px;
    left: 9px;
    transform: rotate(108deg);
  }
  /* 2本目の線の位置を設定 */
  .hamburger span:nth-child(2) {
    top: 36px;
    left: 15px;
    transform: rotate(108deg);
  }
  /* 3本目の線の位置を設定 */
  .hamburger span:nth-child(3) {
    top: 36px;
    left: 21px;
    transform: rotate(108deg);
  }
  /*
  ハンバーガーメニューの線の設定（メニューが開いている時）
  1本目の線を-45度回転
  */
  .hamburger.active span:nth-child(1) {
    top: 37px;
    left: 21px;
    background :$cWhite;
    transform: rotate(-25deg);
  }
  /* 2本目と3本目は重ねて45度回転 */
  .hamburger.active span:nth-child(2),
  .hamburger.active span:nth-child(3) {
    top: 36px;
    left: 21px;
    background: $cWhite;
    transform: rotate(25deg);
  }
/*
メニューの設定
最初は閉じている状態なので、「opacity: 0;」「visibility: hidden;」
で要素を非表示にしておく
*/
#mobile-navi {
    width: 100%;
    height: auto;
    background-color: rgba(55,63,73, 0.9);
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    opacity: 0;
    text-align: center;
    transition: opacity 0.6s ease, visibility 0.6s ease;
    visibility: hidden;
  }
  /*
  ハンバーガーメニューがクリックされた際に、jQueryで#naviにactiveクラスを追加して、
  メニューを表示させる。
  */
  #mobile-navi.active {
    opacity: 1;
    visibility: visible;
  }
  #mobile-navi .logo {
    width: 100px;
    position: absolute;
    top: 40px;
    left: 50px;
  }

  #mobile-navi .mobile-menu {
    margin: 0px 0 0px 0;
    padding: 0;
  }
  #mobile-navi .mobile-menu li {
    list-style: none;
    padding: 18px 0;
  }
  #mobile-navi .mobile-menu a {
    color: #fff;
    font-weight: bold;
    display: block;
    width: 100%;
  }
  #mobile-navi .btn {
    width: 250px;
    border: solid 1px #fff;
    color: #fff;
    display: block;
    font-weight: bold;
    padding: 15px 0;
    margin: 0 auto;
    position: relative;
    transition: 0.3s ease-in-out;
  }
  /* ボタンの右と下の線は疑似要素で設定 */
  #mobile-navi .btn::after {
    content: "　";
    width: 250px;
    border-right: solid 1px #fff;
    border-bottom: solid 1px #fff;
    padding: 15px 0;
    position: absolute;
    right: -6px;
    bottom: -6px;
  }
  #mobile-navi .btn:hover {
    background-color: #fff;
    color: #ff2a2a;
  }
  

// .mobile-menu {
//   &__nav {
//     // padding-top: 100px;
//   }
//   &__ul {
//     padding: 0;
//     margin: 0;
//   }

//   &__li {
//     list-style: none;
//     width: 100%;
//     text-align: center;
//     position: relative;

//     & > a {
//       text-decoration: none;
//       color: #534741;
//       width: 90%;
//       margin: 0 auto;
//     }
//   }
//   &__link {
//     display: block;
//     height: 60px;
//     line-height: 60px;
//     color: #534741;
//     text-decoration: none;
//     font-size: 20px;
//     font-weight: bold;
//   }

//   &__btn {
//     background-color: unset;
//     border: none;
//     outline: none !important;
//     cursor: pointer;
//     // display: none;

//     & > span {
//       background-color: black;
//       width: 35px;
//       height: 2px;
//       display: block;
//       margin-bottom: 9px;
//       transition: transform 0.7s;

//       &:last-child {
//         margin-bottom: 0;
//       }
//     }
//   }

//   &__cover {
//     position: fixed;
//     top: 100px;
//     left: 0;
//     right: 0;
//     z-index: 99;
//     width: 100%;
//     height: 0;
//     background: rgba(255, 255, 255, 1);
//     opacity: 0;
//     text-align: center;
//     transition: opacity 0.6s ease, visibility 0.9s ease;
//     visibility: hidden;
//   }
// }

// .menu-open {
//   & .mobile-menu {
//     &__cover {
//       width: 100%;
//       height: 100vh;
//       opacity: 1;
//       visibility: visible;
//     }

//     &__item {

//       @for $i from 1 through 5 {
//         &:nth-child(#{$i}) {
//           transition-delay: $i * 0.07s;
//         }
//       }
//     }

//     &__btn {
//       & > span {
//         background-color: black;

//         &:nth-child(1) {
//           transition-delay: 70ms;
//           transform: translateY(11px) rotate(135deg);
//         }
//         &:nth-child(2) {
//           transition-delay: 0s;
//           transform: translateX(-18px) scaleX(0);
//         }
//         &:nth-child(3) {
//           transition-delay: 140ms;
//           transform: translateY(-11px) rotate(-135deg);
//         }
//       }
//     }
//   }
// }


