@media screen and (max-width: 960px) {
  .header {
   
    height: 75px;
    &__nav {
      display: none;
    }

    &__logo {
      margin-bottom: 0;
      position: relative;
      bottom: 10px;
    }
  }

  .hamburger {
    display: block;
  }

  #mobile-navi {
    top: 75px;
  }

  .txt-p {
    text-align: center;
  }

  .bg-po {
    background-size: cover;
  }
  .pt-md {
    padding: 70px 0 0 0;
  }

  .bu {
    &__bg {
      margin-bottom: 70px;
    }

    &__ttl {
      .corporate:nth-child(2) & {
        text-align: center;
      }
    }

    &__access {
      flex-direction: column;
    }

    &__map {
      width: 100%;
    }

    &__enkaku {
      width: 100%;
      margin-top: 20px;
    }
    &__panel {
      flex-direction: column-reverse;

      .corporate:nth-child(2) & {
        flex-direction: column-reverse;
      }

      &-ttl {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      &-txt {
        &-center {
          margin-bottom: 20px;
        }
      }
      &-right {
        width: 100%;
      }
      &-left {
        width: 100%;
      }
    }
  }

  .btn-blue-sp {
    display: block;
  }
  .he-lr {
    height: auto;
  }
  .info {
    &__box {
      margin: 0 auto 30px;
      padding: 20px;
    }
  }

  .bu__panel-right > .btn {
    display: none;
  }

  .contact {
    &__ttl {
      & > h3 {
        font-size: 70px;
      }
    }
  }

  .after-no {
    &::after {
      content: none !important;
    }
  }

  .info {
    &__ttl {
      display: block;
    }
  }

  .thumbnail {
    width: 100px;
}
  .footer {
    &__wrap {
      flex-direction: column;
      align-items: center;
    }
    &__left {
      margin-bottom: 30px;
    }
    &__ul {
      justify-content: flex-start;
      width: 90%;
      margin: 0 auto;
    }

    &__li {
      width: 30%;
      padding: 12px 0;
      & > a {
        width: 120px;
        display: inline-block;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 2px;
          background-color: #373f49;
          width: 1px;
          height: 15px;
          color: #373f49;
        }
      }
    }
  }
}
