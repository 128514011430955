@media screen and (max-width: 1280px) {
  .header {
    &__inner {
      margin-left: 10px;
    }
    &__li {
      // margin-left: 15px;
      // padding-right: 15px;
      & > a {
        font-size: 14px;
      }
    }
  }

  .bu {
    &__bg {
      height: auto;
    }

    &__ttl {
        text-align: center;
    }
    &__panel {
      &-right {
        width: 48%;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .header {
    &__li {
      margin-left: 15px;
      padding-right: 15px;
    }
    &__tel {
      right: 35%;
    }
  }
  .hero {
    &__ttl {
      font-size: 30px;
    }
  }

  .bu {
    &__bg {
      background-position: top;
      background-size: contain;

      .corporate:nth-child(2) & {
        background-position: top;
      }
    }
  }
}
