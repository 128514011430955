@charset "UTF-8";
.appear.up .item {
  transform: translateY(46px); }

.appear.down .item {
  transform: translateY(-36px); }

.appear.left .item {
  transform: translateX(40px); }

.appear.right .item {
  transform: translateX(-40px); }

.appear .item {
  transition: all 1.8s;
  opacity: 0; }

.appear.inview .item {
  opacity: 1;
  transform: none; }
  .appear.inview .item:nth-child(1) {
    transition-delay: 0.1s; }
  .appear.inview .item:nth-child(2) {
    transition-delay: 0.2s; }
  .appear.inview .item:nth-child(3) {
    transition-delay: 0.3s; }
  .appear.inview .item:nth-child(4) {
    transition-delay: 0.4s; }
  .appear.inview .item:nth-child(5) {
    transition-delay: 0.5s; }
  .appear.inview .item:nth-child(6) {
    transition-delay: 0.6s; }
  .appear.inview .item:nth-child(7) {
    transition-delay: 0.7s; }
  .appear.inview .item:nth-child(8) {
    transition-delay: 0.8s; }
  .appear.inview .item:nth-child(9) {
    transition-delay: 0.9s; }
  .appear.inview .item:nth-child(10) {
    transition-delay: 1s; }

.btn {
  position: relative;
  display: inline-block; }
  .btn.filled {
    background-image: url(../images/contact.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 190px;
    height: 70px;
    line-height: 70px;
    text-align: center; }
  .btn-blue {
    font-size: 14px;
    background-color: #00437C;
    width: 350px;
    padding: 21px 0;
    line-height: 2;
    display: block;
    text-align: center;
    color: white; }
    .btn-blue-sp {
      font-size: 14px;
      background-color: #00437C;
      width: 350px;
      padding: 21px 0;
      line-height: 2;
      display: block;
      text-align: center;
      color: white; }
      .btn-blue-sp::after {
        content: '';
        background-image: url(../images/yajirushi_wh.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 28px;
        position: absolute;
        top: 22px;
        right: 24px; }
    .btn-blue::after {
      content: '';
      background-image: url(../images/yajirushi_wh.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 28px;
      position: absolute;
      top: 22px;
      right: 24px; }
    .btn-blue > img {
      position: absolute;
      right: 20px; }
    .btn-blue:hover {
      color: #00437C;
      background-color: #E5E8EF; }
      .btn-blue:hover::after {
        background-image: url(../images/yajirushi_bl.png); }

.info__box {
  width: 90%;
  margin: 30px auto 0;
  max-width: 1200px;
  background-color: #fff;
  padding: 30px 40px; }
  .info__box-ttl {
    color: #00437C;
    margin-right: 20px; }
  .info__box-blk {
    display: block;
    margin-bottom: 10px;
    font-size: 14px; }

.fill-blue {
  background-color: #00437C; }

.fill-white {
  background-color: #fff; }

.blue-txt {
  color: #00437C !important; }

.button {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 30;
  /*デフォルトで非表示にする*/
  opacity: 0;
  visibility: hidden; }

/*このクラスが付与されると表示する*/
.active {
  opacity: 1;
  visibility: visible; }

/*このクラスが付与されると表示する*/
.absolute {
  position: absolute;
  top: -45px;
  bottom: auto; }

.hero {
  position: relative;
  width: 100%;
  height: 100vh; }
  .hero__ttl {
    color: white;
    font-size: 40px;
    position: absolute;
    bottom: 18%;
    left: 10%;
    z-index: 10; }
    .hero__ttl > h1 {
      margin-bottom: 0; }
  .hero__scroll {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px; }

/* スライダー全体 */
.slider-animation {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: #000; }

/* スライダー画像 */
.slider-animation .image {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }

/*
①スライド画像
表示する画像を定義
*/
.slider-animation .image:nth-of-type(1) {
  animation-delay: 0s;
  background-image: url(../images/hero1.jpg); }

.slider-animation .image:nth-of-type(2) {
  animation-delay: 6s;
  background-image: url(../images/hero2.jpg); }

.slider-animation .image:nth-of-type(3) {
  animation-delay: 10s;
  background-image: url(../images/hero3.jpg); }

/*
②アニメーション設定
animation-durationのみ変更
*/
.slider-animation .image {
  animation-name: anime;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  /* ①で設定したanimation-delayを合計を挿入 */ }

/* アニメーション定義 */
@keyframes anime {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: scale(1.15); } }

/*========= スクロールダウンのためのCSS ===============*/
/*スクロールダウン全体の場所*/
.scrolldown4 {
  /*描画位置※位置は適宜調整してください*/
  position: absolute;
  bottom: 5%;
  left: 50%;
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  line-height: 1;
  width: 18px;
  height: 18px;
  border: 0.1em solid currentColor;
  border-left: 0;
  border-bottom: 0;
  box-sizing: border-box;
  transform: translateX(-50%) translateY(-25%) rotate(135deg);
  margin-bottom: 20px;
  /*矢印の動き1秒かけて永遠にループ*/
  animation: arrowmove 1.8s ease-in-out infinite; }

/*下からの距離が変化して全体が下→上→下に動く*/
@keyframes arrowmove {
  0% {
    bottom: 1%; }
  50% {
    bottom: 2%; }
  100% {
    bottom: 1%; } }

.swiper-wrapper {
  /* wrapperのサイズを調整 */
  width: 100%;
  height: 300px; }

.swiper-slide {
  /* スライドのサイズを調整、中身のテキスト配置調整、背景色 */
  color: #ffffff;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 300px;
  text-align: center; }
  .swiper-slide > img {
    width: 100%;
    height: 100vh;
    object-fit: cover; }

.swiper-pagination-bullet {
  width: 35px !important;
  height: 5px !important;
  background-color: #ffffff !important;
  border-radius: 0% !important; }

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  left: 40% !important;
  bottom: 30px !important; }

.slick-next:before {
  content: url(../images/slick-next.png) !important;
  position: relative;
  bottom: 25px;
  right: -35px; }

.slick-prev:before {
  content: url(../images/slick-prev.png) !important;
  position: relative;
  bottom: 20px;
  left: -35px; }

.swiper-container {
  /* スライド アクティブ時 */ }
  .swiper-container .swiper-slide[class*=-active] .slide-media img {
    transition-delay: 0s;
    transform: scale(1.05); }
  .swiper-container .swiper-slide[class*=-active] .slide-title {
    animation: mv01-fadeIn 2s 0.5s var(--easing) both; }

@keyframes mv01-fadeIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
    filter: blur(300px); }
  100% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0); } }

.animate-title,
.tween-animate-title {
  opacity: 0; }
  .animate-title.inview,
  .tween-animate-title.inview {
    opacity: 1; }
    .animate-title.inview .char,
    .tween-animate-title.inview .char {
      display: inline-block; }
  .animate-title .char,
  .tween-animate-title .char {
    opacity: 0; }

.animate-title.inview .char {
  animation-name: kf-animate-chars;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.39, 1.57, 0.58, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both; }
  .animate-title.inview .char:nth-child(1) {
    animation-delay: 0.04s; }
  .animate-title.inview .char:nth-child(2) {
    animation-delay: 0.08s; }
  .animate-title.inview .char:nth-child(3) {
    animation-delay: 0.12s; }
  .animate-title.inview .char:nth-child(4) {
    animation-delay: 0.16s; }
  .animate-title.inview .char:nth-child(5) {
    animation-delay: 0.2s; }
  .animate-title.inview .char:nth-child(6) {
    animation-delay: 0.24s; }
  .animate-title.inview .char:nth-child(7) {
    animation-delay: 0.28s; }
  .animate-title.inview .char:nth-child(8) {
    animation-delay: 0.32s; }
  .animate-title.inview .char:nth-child(9) {
    animation-delay: 0.36s; }
  .animate-title.inview .char:nth-child(10) {
    animation-delay: 0.4s; }
  .animate-title.inview .char:nth-child(11) {
    animation-delay: 0.44s; }
  .animate-title.inview .char:nth-child(12) {
    animation-delay: 0.48s; }
  .animate-title.inview .char:nth-child(13) {
    animation-delay: 0.52s; }
  .animate-title.inview .char:nth-child(14) {
    animation-delay: 0.56s; }
  .animate-title.inview .char:nth-child(15) {
    animation-delay: 0.6s; }
  .animate-title.inview .char:nth-child(16) {
    animation-delay: 0.64s; }
  .animate-title.inview .char:nth-child(17) {
    animation-delay: 0.68s; }
  .animate-title.inview .char:nth-child(18) {
    animation-delay: 0.72s; }
  .animate-title.inview .char:nth-child(19) {
    animation-delay: 0.76s; }
  .animate-title.inview .char:nth-child(20) {
    animation-delay: 0.8s; }
  .animate-title.inview .char:nth-child(21) {
    animation-delay: 0.84s; }
  .animate-title.inview .char:nth-child(22) {
    animation-delay: 0.88s; }
  .animate-title.inview .char:nth-child(23) {
    animation-delay: 0.92s; }
  .animate-title.inview .char:nth-child(24) {
    animation-delay: 0.96s; }
  .animate-title.inview .char:nth-child(25) {
    animation-delay: 1s; }
  .animate-title.inview .char:nth-child(26) {
    animation-delay: 1.04s; }
  .animate-title.inview .char:nth-child(27) {
    animation-delay: 1.08s; }
  .animate-title.inview .char:nth-child(28) {
    animation-delay: 1.12s; }
  .animate-title.inview .char:nth-child(29) {
    animation-delay: 1.16s; }
  .animate-title.inview .char:nth-child(30) {
    animation-delay: 1.2s; }

@keyframes kf-animate-chars {
  0% {
    opacity: 0;
    transform: translateY(-50%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.cover-slide {
  position: relative;
  overflow: hidden; }
  .cover-slide::after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bcbcbe;
    opacity: 1; }
  .cover-slide.inview::after {
    opacity: 1;
    animation-name: kf-cover-slide;
    animation-duration: 1.6s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both; }

@keyframes kf-cover-slide {
  0% {
    transform-origin: left;
    transform: scaleX(0); }
  50% {
    transform-origin: left;
    transform: scaleX(1); }
  50.1% {
    transform-origin: right;
    transform: scaleX(1); }
  100% {
    transform-origin: right;
    transform: scaleX(0); } }

.img-zoom, .bg-img-zoom {
  opacity: 0; }
  .inview .img-zoom, .inview .bg-img-zoom {
    opacity: 1;
    transition: transform 0.3s ease;
    animation-name: kf-img-show;
    animation-duration: 1.6s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none; }
    .inview .img-zoom:hover, .inview .bg-img-zoom:hover {
      transform: scale(1.05); }

@keyframes kf-img-show {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  50.1% {
    opacity: 1;
    transform: scale(1.5); }
  100% {
    opacity: 1; } }

.hover-darken::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: background-color 0.3s ease;
  pointer-events: none;
  animation-name: kf-img-show;
  animation-duration: 1.6s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none; }

.hover-darken:hover::before {
  background-color: rgba(0, 0, 0, 0.4); }

.bg-img-zoom {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%; }

.img-bg50 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover; }
  .img-bg50::before {
    display: block;
    content: "";
    padding-top: 50%; }

.img-bg60 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%; }
  .img-bg60::after {
    display: block;
    content: "";
    padding-top: 60%; }

/*====================================================================
slick
====================================================================*/
.sliderArea {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 25px; }

.sliderArea.w300 {
  max-width: 300px; }

.slick-slide {
  margin: 0 5px; }

.slick-slide img {
  width: 100%;
  height: auto; }

.slick-prev, .slick-next {
  z-index: 1; }

.slick-prev:before, .slick-next:before {
  color: #000; }

.slick-slide {
  transition: all ease-in-out .3s; }

.slick-active {
  opacity: 1; }

.slick-current {
  opacity: 1; }

.thumb {
  margin: 20px 0 0; }

.thumb .slick-slide {
  cursor: pointer; }

.thumb .slick-slide:hover {
  opacity: .7; }

/*====================================================================
.full-screen
====================================================================*/
.full-screen .slick-list {
  overflow: visible; }

.full-screen.slider {
  max-width: 500px;
  margin: 0 auto; }

.hamburger {
  width: 75px;
  height: 75px;
  background-color: #00437c;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
  cursor: pointer;
  transition: 0.3s; }

.hamburger:hover {
  opacity: 0.7; }

/* ハンバーガーメニューの線の設定（メニューが閉じている時） */
.hamburger span {
  width: 40px;
  height: 2px;
  background: white;
  position: absolute;
  left: 21px;
  transition: 0.3s ease-in-out; }

/* 1本目の線の位置を設定 */
.hamburger span:nth-child(1) {
  top: 36px;
  left: 9px;
  transform: rotate(108deg); }

/* 2本目の線の位置を設定 */
.hamburger span:nth-child(2) {
  top: 36px;
  left: 15px;
  transform: rotate(108deg); }

/* 3本目の線の位置を設定 */
.hamburger span:nth-child(3) {
  top: 36px;
  left: 21px;
  transform: rotate(108deg); }

/*
  ハンバーガーメニューの線の設定（メニューが開いている時）
  1本目の線を-45度回転
  */
.hamburger.active span:nth-child(1) {
  top: 37px;
  left: 21px;
  background: white;
  transform: rotate(-25deg); }

/* 2本目と3本目は重ねて45度回転 */
.hamburger.active span:nth-child(2),
.hamburger.active span:nth-child(3) {
  top: 36px;
  left: 21px;
  background: white;
  transform: rotate(25deg); }

/*
メニューの設定
最初は閉じている状態なので、「opacity: 0;」「visibility: hidden;」
で要素を非表示にしておく
*/
#mobile-navi {
  width: 100%;
  height: auto;
  background-color: rgba(55, 63, 73, 0.9);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  opacity: 0;
  text-align: center;
  transition: opacity 0.6s ease, visibility 0.6s ease;
  visibility: hidden; }

/*
  ハンバーガーメニューがクリックされた際に、jQueryで#naviにactiveクラスを追加して、
  メニューを表示させる。
  */
#mobile-navi.active {
  opacity: 1;
  visibility: visible; }

#mobile-navi .logo {
  width: 100px;
  position: absolute;
  top: 40px;
  left: 50px; }

#mobile-navi .mobile-menu {
  margin: 0px 0 0px 0;
  padding: 0; }

#mobile-navi .mobile-menu li {
  list-style: none;
  padding: 18px 0; }

#mobile-navi .mobile-menu a {
  color: #fff;
  font-weight: bold;
  display: block;
  width: 100%; }

#mobile-navi .btn {
  width: 250px;
  border: solid 1px #fff;
  color: #fff;
  display: block;
  font-weight: bold;
  padding: 15px 0;
  margin: 0 auto;
  position: relative;
  transition: 0.3s ease-in-out; }

/* ボタンの右と下の線は疑似要素で設定 */
#mobile-navi .btn::after {
  content: "　";
  width: 250px;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  padding: 15px 0;
  position: absolute;
  right: -6px;
  bottom: -6px; }

#mobile-navi .btn:hover {
  background-color: #fff;
  color: #ff2a2a; }

html {
  scroll-behavior: smooth; }

body {
  font-family: "Noto Sans JP", sans-serif;
  color: #373f49; }

img {
  max-width: 100%;
  vertical-align: bottom; }

a {
  color: #373f49; }
  a:hover {
    color: #373f49;
    text-decoration: none;
    opacity: 0.7; }

.fo-robot, .header__tel, .bu__ttl > h2, .contact__ttl > h3, .main__ttl-top, .ttl-sec, .tel {
  font-family: "Roboto", sans-serif; }

.font-sm, .bu__card-bottom {
  font-size: 13px; }

.font-md, .bu__card-txt, .bu__enkaku {
  font-size: 14px; }

.font-lr, .bu__card-ttl {
  font-size: 18px; }

.font-lg {
  font-size: 26px; }

.content-width, .bu__access, .re__inner, .footer__wrap, .main__inner {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px; }

#global-container {
  overflow: hidden; }

.po-md {
  position: relative;
  top: 20px; }

/*-------------------------------------------
ヘッダー
-------------------------------------------*/
/*
ハンバーガ―メニュー
*/
.hamburger {
  display: none; }

/* ロゴに関するスタイル */
.header-logo.headerLogoScroll.-before {
  display: none; }

.header-logo.-after {
  display: none; }

.header-logo.headerLogoScroll.-after {
  display: block;
  margin-top: 27px; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  z-index: 100; }
  .header.change {
    background-color: white; }
  .header__logo {
    width: 300px;
    margin-bottom: 20px; }
  .header-ttl {
    color: #fff;
    font-size: 12px;
    position: relative;
    top: 20px;
    left: 5px; }
    .header-ttl.change-color {
      color: #373f49; }
  .header-sec {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    z-index: 100; }
    .header-sec.triggered {
      background-color: #fff; }
  .header__nav {
    display: block;
    position: relative; }
  .header__tel {
    font-size: 26px;
    position: absolute;
    right: 33%;
    font-weight: 500; }
    .header__tel > a {
      color: #fff;
      font-size: 18px; }
      .header__tel > a.change-color {
        color: #00437c; }
  .header__ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none; }
    .header__ul-wrapper {
      padding-top: 30px;
      display: flex;
      flex-wrap: row; }
  .header__li {
    margin-left: 25px;
    position: relative;
    border-right: 1px solid #fff;
    padding-right: 25px; }
    .header__li.change-color {
      border-right: 1px solid #373f49; }
    .header__li:nth-child(4) {
      border-right: 0; }
    .header__li:last-child {
      padding-right: 0;
      border-right: none; }
    .header__li > a {
      font-size: 16px;
      display: block;
      color: white;
      text-decoration: none !important;
      text-transform: uppercase; }
      .header__li > a.change-color {
        color: #373f49; }
      .header__li > a > span {
        font-size: 16px; }
      .header__li > a > img {
        position: relative;
        top: 25px;
        left: -4px; }
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 60px; }

.txt-p {
  text-align: left; }

.bu__bg {
  background-image: url(../images/business_bg.jpg);
  background-position: left;
  background-size: 65% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
  margin-bottom: 250px; }
  .corporate:nth-child(2) .bu__bg {
    background-image: url(../images/corporate_bg.jpg);
    background-position: right; }

.bu__ttl {
  text-align: right; }
  .corporate:nth-child(2) .bu__ttl {
    text-align: left; }
  .bu__ttl > h2 {
    color: #00437C;
    font-size: 130px;
    font-weight: 100;
    margin-bottom: 0; }

.bu__panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }
  .corporate:nth-child(2) .bu__panel {
    flex-direction: row-reverse; }
  .bu__panel-wrap {
    padding: 80px 0 0 0;
    width: 90%;
    margin: 0 auto;
    max-width: 1200px; }
  .bu__panel-ttl {
    margin-bottom: 45px; }
    .bu__panel-ttl > img {
      position: relative;
      bottom: 5px; }
    .bu__panel-ttl-span {
      font-size: 26px;
      color: #00437C;
      font-weight: bold; }
  .bu__panel-txt-center {
    margin-bottom: 45px; }
    .bu__panel-txt-center > p {
      font-size: 18px;
      color: #00437C;
      margin-bottom: 0;
      letter-spacing: 0.05em;
      line-height: 1.8;
      font-weight: bold; }
  .bu__panel-txt-bottom > p {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 2; }
  .bu__panel-left {
    width: 45%; }
  .bu__panel-right {
    width: auto; }

.bu__inner {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px; }

.bu__card {
  width: 47%;
  margin-bottom: 90px; }
  .bu__card-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; }
  .bu__card-blk {
    display: flex;
    flex-direction: row; }
  .bu__card-img {
    width: 40%;
    position: relative;
    bottom: 30px; }
  .bu__card-ttl {
    background-color: #00437C;
    color: #fff;
    padding: 3px 0 3px 7px;
    width: 70%;
    margin: 0 0 0 auto; }
  .bu__card-txt {
    line-height: 2;
    width: 90%;
    margin-bottom: 0;
    margin-top: 10px; }
  .bu__card-bottom {
    width: 95%;
    margin: 0 0 0 auto;
    margin-top: 15px; }
    .bu__card-bottom-ttl {
      margin-bottom: 10px; }

.bu__map {
  width: 50%; }

.bu__access {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px; }

.bu__enkaku {
  width: 45%; }
  .bu__enkaku-ttl {
    margin-bottom: 10px; }
  .bu__enkaku-time {
    color: #00437C;
    margin-bottom: 3px; }
  .bu__enkaku-txt {
    margin-bottom: 17px; }

table {
  width: 100%; }

tr {
  font-size: 14px; }

tr td:first-child {
  color: #00437C;
  width: 20%; }

td {
  padding: 6px 0; }

#recruit {
  background-image: url(../images/recruit_bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  padding-top: 90px; }

.re__txt {
  margin-top: 30px;
  margin-bottom: 30px; }
  .re__txt > p {
    margin-bottom: 0;
    color: white;
    font-weight: bold; }

.re__box {
  width: 100%;
  background-color: #fff;
  padding: 50px 30px; }
  .re__box-blk {
    font-size: 14px;
    display: flex;
    justify-content: start; }
  .re__box-ttl {
    color: #00437C;
    width: 13%;
    margin-bottom: 16px; }
  .re__box-txt {
    width: 80%; }

.btn-blue-sp {
  display: none; }

#contact {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center; }

.contact__ttl > h3 {
  font-size: 90px;
  color: #00437C;
  margin-bottom: 0;
  font-weight: 100; }

.contact__ttl-ja > p {
  margin-bottom: 0;
  position: relative;
  bottom: 20px;
  font-size: 26px;
  color: #00437C;
  font-weight: bold; }

.contact__btn > a {
  margin: 0 auto; }

.contact__center {
  font-size: 18px;
  margin-bottom: 40px;
  margin-top: 10px;
  color: #00437C;
  font-weight: bold; }

.after-no-last::after {
  content: none !important; }

.footer {
  position: relative; }
  .footer__nav {
    padding: 28px 0;
    border-top: 1px solid #373f49; }
  .footer__ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center; }
  .footer__li {
    list-style: none; }
    .footer__li > a {
      font-size: 14px; }
      .footer__li > a::after {
        content: "";
        background-color: #373f49;
        width: 1px;
        height: 15px;
        color: #373f49;
        margin: 0 30px;
        display: inline-block; }
  .footer__txt {
    padding-left: 40px;
    font-size: 14px; }
  .footer__inner {
    background-color: #E5E8EF;
    padding: 30px 0; }
  .footer__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .footer__left {
    display: flex;
    flex-direction: row;
    align-items: flex-start; }
  .footer__right {
    font-size: 12px; }
  .footer__logo {
    margin-right: 30px; }
  .footer__company {
    font-size: 16px; }
  .footer__add {
    font-size: 12px;
    margin-top: 23px; }

.current-info {
  border-bottom: 2px solid #00437c !important;
  color: #00437c !important; }

.main__ttl {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 50px; }
  .main__ttl-top {
    font-weight: 100;
    font-size: 80px;
    color: #00437C; }
  .main__ttl-sub {
    margin-left: 20px;
    position: relative;
    top: 8px; }
    .main__ttl-sub > span {
      font-size: 20px;
      color: #00437c; }

.br__nav {
  margin-bottom: 10px; }

.br__ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end; }

.br__li {
  list-style: none;
  margin-right: 7px;
  font-size: 12px; }

.bu__bg-sec {
  background-image: url(../images/business_bg.jpg);
  background-position: left top;
  background-size: 70% 50%;
  background-repeat: no-repeat;
  width: 100%;
  margin-top: 75px; }

.bu__bg-thr {
  background-image: url(../images/contact_bg.jpg);
  background-position: left top;
  background-size: 70% 40%;
  background-repeat: no-repeat;
  width: 100%;
  margin-top: 75px; }

.ttl-sec {
  font-size: 80px;
  color: #00437C;
  margin-right: 60px; }

.content__sec {
  padding: 50px 60px;
  background-color: #fff; }

.content__thr {
  padding: 50px 60px;
  background-color: #fff; }

.content__info {
  background-image: url(../images/circle-before.png);
  background-size: contain;
  background-repeat: no-repeat; }
  .content__info:hover {
    background-image: url(../images/circle-after.png);
    background-size: contain;
    background-repeat: no-repeat; }

.he-lr {
  height: 650px; }

.info__blk {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; }
  .info__blk-a {
    padding-bottom: 10px;
    width: 80%; }
    .info__blk-a:hover {
      opacity: 0.8;
      border-bottom: 1px solid #00437c;
      padding-bottom: 10px; }

.info__date {
  color: #00437c;
  margin-right: 20px; }

.info__top-date {
  color: #00437c;
  margin-bottom: 10px; }

.info__top-ttl {
  margin-bottom: 30px; }

.info__des-txt {
  font-size: 14px;
  line-height: 2;
  margin-bottom: 60px; }

.pr__blk {
  font-size: 14px;
  margin-bottom: 30px; }

.pr__ttl {
  color: #00437C;
  margin-bottom: 10px; }

.pr__name {
  font-size: 14px;
  margin: 50px 0 0 auto;
  width: 180px; }

.pr__btn > a {
  margin: 100px auto 70px; }

.co__txt {
  margin-bottom: 40px; }

.co__tel {
  color: #00437c;
  font-size: 50px;
  margin: 20px 0 40px 0; }
  .co__tel-blk {
    background-color: #00437c;
    width: 230px;
    color: #fff;
    text-align: center;
    padding: 3px 0; }

.co__mail-blk {
  background-color: #00437c;
  width: 300px;
  color: #fff;
  text-align: center;
  padding: 3px 0;
  margin-bottom: 20px; }

.co__form-txt {
  line-height: 2;
  font-size: 14px; }
  .co__form-txt > span {
    padding: 0 8px;
    width: 45px;
    text-align: center;
    background-color: #b82b36;
    color: #fff;
    margin-right: 2px;
    margin-left: 10px; }

.co__tha-ttl {
  color: #00437c;
  font-size: 20px;
  margin-bottom: 40px; }

.co__tha-txt {
  font-size: 16px;
  margin-bottom: 30px; }
  .co__tha-txt-bottom {
    margin-bottom: 60px; }

.tel {
  font-size: 30px;
  color: #00437c;
  font-weight: bold; }

.time {
  font-size: 14px;
  color: #373f49; }

.form > table > tbody > tr {
  margin: 20px 0;
  display: block; }

.form > table > tbody > tr > th {
  display: block;
  font-size: 14px;
  font-weight: normal;
  padding: 8px 0 8px 10px;
  position: relative; }
  .form > table > tbody > tr > th::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 5px;
    height: 30px;
    background-color: #00437c; }

.form > table > tbody > tr > td {
  display: block;
  color: #666565;
  font-size: 14px; }

.red {
  padding: 0 8px;
  width: 45px;
  text-align: center;
  background-color: #b82b36;
  color: #fff;
  margin-right: 2px;
  margin-left: 10px; }

.form {
  width: 100%; }

input[type="text"] {
  width: 100%;
  border: 1px solid #57524d;
  padding: 8px 10px;
  font-size: 12px;
  max-width: 800px; }

.mw-form input[type="text"] {
  width: 25%;
  margin-left: 10px;
  border: 1px solid #57524d; }

.zip__span {
  font-size: 13px; }

.agree {
  margin-top: 40px; }

.agree-1 {
  display: flex;
  align-items: center;
  justify-content: center; }

input[type="checkbox"] {
  width: 25px;
  height: 25px;
  margin-right: 15px; }

.line {
  color: #00437C;
  border-bottom: 1px solid #00437C; }

input[type="submit"] {
  background: #9bb2cc url(../images/yajirushi_wh.png) 94% center no-repeat;
  font-size: 14px;
  background-color: #00437C;
  width: 350px;
  padding: 21px 0;
  line-height: 2;
  display: block;
  text-align: center;
  color: white;
  border: 1px solid transparent;
  margin: 60px auto 90px; }

textarea {
  width: 100%; }

.thanks {
  width: 90%;
  margin: 60px auto;
  max-width: 800px;
  line-height: 2;
  letter-spacing: 0.1rem;
  text-align: center; }
  .thanks__images {
    width: 300px;
    height: 120px;
    margin: 60px auto 90px; }
    .thanks__images img {
      width: 100%;
      height: 100%; }

.co__current {
  width: 30%;
  background-color: #e5e8ef;
  transform: skew(-20deg); }
  .co__current-wrap {
    width: 100%;
    display: flex;
    flex-direction: row; }

ul.test3 {
  list-style: none;
  display: flex;
  /*横並びにする*/
  width: 100%;
  margin: 50px 0;
  padding: 0; }

ul.test3 li {
  text-align: center;
  width: 30%;
  height: 40px;
  position: relative;
  line-height: 2.3;
  padding: 0 3px; }
  ul.test3 li::before {
    content: "";
    position: absolute;
    top: 0;
    left: -13px;
    background-image: url(../images/contact_ttl.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 40px;
    z-index: 2; }

ul.test3 li a {
  height: 100%;
  display: block;
  position: relative;
  padding: 5px 15px;
  text-decoration: none;
  font-size: 12px;
  color: #00437c;
  z-index: 1; }
  ul.test3 li a:hover {
    opacity: 1; }

ul.test3 li a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #e5e8ef;
  transform: skewX(-19deg); }

.current-co {
  color: #fff !important; }
  .current-co::before {
    background-color: #00437c !important;
    width: 99%;
    margin: 0 auto; }

a:not([href]):not([tabindex]) {
  color: #00437C; }

@media screen and (max-width: 1280px) {
  .header__inner {
    margin-left: 10px; }
  .header__li > a {
    font-size: 14px; }
  .bu__bg {
    height: auto; }
  .bu__ttl {
    text-align: center; }
  .bu__panel-right {
    width: 48%; } }

@media screen and (max-width: 1000px) {
  .header__li {
    margin-left: 15px;
    padding-right: 15px; }
  .header__tel {
    right: 35%; }
  .hero__ttl {
    font-size: 30px; }
  .bu__bg {
    background-position: top;
    background-size: contain; }
    .corporate:nth-child(2) .bu__bg {
      background-position: top; } }

@media screen and (max-width: 960px) {
  .header {
    height: 75px; }
    .header__nav {
      display: none; }
    .header__logo {
      margin-bottom: 0;
      position: relative;
      bottom: 10px; }
  .hamburger {
    display: block; }
  #mobile-navi {
    top: 75px; }
  .txt-p {
    text-align: center; }
  .bg-po {
    background-size: cover; }
  .pt-md {
    padding: 70px 0 0 0; }
  .bu__bg {
    margin-bottom: 70px; }
  .corporate:nth-child(2) .bu__ttl {
    text-align: center; }
  .bu__access {
    flex-direction: column; }
  .bu__map {
    width: 100%; }
  .bu__enkaku {
    width: 100%;
    margin-top: 20px; }
  .bu__panel {
    flex-direction: column-reverse; }
    .corporate:nth-child(2) .bu__panel {
      flex-direction: column-reverse; }
    .bu__panel-ttl {
      margin-top: 20px;
      margin-bottom: 20px; }
    .bu__panel-txt-center {
      margin-bottom: 20px; }
    .bu__panel-right {
      width: 100%; }
    .bu__panel-left {
      width: 100%; }
  .btn-blue-sp {
    display: block; }
  .he-lr {
    height: auto; }
  .info__box {
    margin: 0 auto 30px;
    padding: 20px; }
  .bu__panel-right > .btn {
    display: none; }
  .contact__ttl > h3 {
    font-size: 70px; }
  .after-no::after {
    content: none !important; }
  .info__ttl {
    display: block; }
  .thumbnail {
    width: 100px; }
  .footer__wrap {
    flex-direction: column;
    align-items: center; }
  .footer__left {
    margin-bottom: 30px; }
  .footer__ul {
    justify-content: flex-start;
    width: 90%;
    margin: 0 auto; }
  .footer__li {
    width: 30%;
    padding: 12px 0; }
    .footer__li > a {
      width: 120px;
      display: inline-block;
      position: relative; }
      .footer__li > a::after {
        content: "";
        position: absolute;
        top: 0;
        right: 2px;
        background-color: #373f49;
        width: 1px;
        height: 15px;
        color: #373f49; } }

@media screen and (max-width: 780px) {
  .bu__ttl > h2 {
    font-size: 70px; }
  .re__box-ttl {
    margin-bottom: 5px;
    width: 50%; }
  .re__box-blk {
    flex-direction: column;
    margin-bottom: 15px; }
  .bu__card {
    width: 100%; }
    .bu__card-wrap {
      flex-direction: column; }
    .bu__card-blk {
      margin-top: 15px;
      justify-content: space-between; }
    .bu__card-img {
      bottom: 0;
      width: auto; }
    .bu__card-ttl {
      width: 100%;
      text-align: center; }
    .bu__card-txt {
      margin-top: 0;
      width: 70%; }
    .bu__card-bottom {
      margin-top: 26px; }
  .main__inner {
    width: 95%; }
  .main__ttl {
    flex-direction: column;
    align-items: baseline; }
    .main__ttl-top {
      font-size: 60px; }
  .bu__bg-sec {
    background-size: 130%; }
  .bu__bg-thr {
    background-size: 130%; }
  .br__ul {
    margin: 40px 0 20px 0; }
  .content__sec {
    padding: 30px 20px; }
  .content__thr {
    padding: 30px 20px; }
  .tel {
    font-size: 16px; }
  .time {
    display: block; }
  .co__tel {
    font-size: 28px; }
  ul.test3 {
    justify-content: center; }
  .form > table > tbody > tr {
    flex-direction: column;
    align-items: flex-start; } }

@media screen and (max-width: 800px) {
  .houses {
    margin-top: 60px; }
    .houses__inner {
      flex-direction: column; }
    .houses__item {
      width: 100%; } }

@media screen and (max-width: 600px) {
  .hero__ttl {
    font-size: 20px; }
  .hero__scroll {
    bottom: 9%; }
  .bu__card-txt {
    width: 65%; }
  .bu__card-img {
    margin-right: 5%; }
  tr td:first-child {
    width: 17%; }
  .re__box {
    padding: 30px 20px; }
    .re__box-txt {
      width: 100%; }
  .slick-next:before {
    right: 0px; }
  .slick-prev:before {
    left: -10px; }
  .thumbnail {
    width: 40%; }
  .info__blk-a {
    margin-right: 20px;
    width: auto; }
  .footer__wrap {
    width: 95%; } }

@media screen and (max-width: 480px) {
  .header-ttl {
    font-size: 10px; }
  .header__logo {
    width: 260px; }
  .footer__right {
    font-size: 10px; }
  .footer__li {
    width: 33%; }
  .main__ttl {
    padding-top: 20px; }
    .main__ttl-top {
      font-size: 50px; }
  .br__ul {
    margin: 30px 0 10px 0; }
  .main__ttl-sub {
    top: 0; }
  .pr__btn > a {
    width: 300px; }
  .thumbnail {
    position: relative;
    left: 16px; }
  .info__ttl-a {
    margin-right: 20px; }
  input[type="submit"] {
    width: 300px; }
  .form {
    width: 100%; }
  .zip__span {
    display: block; }
  .mw-form input[type="text"] {
    width: 50%;
    margin-left: 10px;
    border: 1px solid #57524d; }
  .mwform-checkbox {
    font-size: 14px; } }
